html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

:root {
  --light-color: #f2f9fd;
  --dark-color: #00044e;
  --error-color: #f44336;
  --border-radius: 6px;
  --main-color: #00044e;
  --main-green: #00c291;
}

.loadingpage {
  height: 100vh;
  background-color: var(--main-color);
  background-image: url('assets/epix-map.png');
  background-position: right;
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: auto 100vh;
  display: flex;
  justify-content: flex-start;
  color: var(--light-color);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner,
.loadingpage .spinner {
  animation: spin 1s infinite linear;
  border: 2px solid transparent;
  border-left: 2px solid var(--main-green);
  border-radius: 16px;
  border-top: 2px solid var(--main-green);
  display: inline-block;
  height: 25px;
  transform: rotate(1deg);
  width: 25px;
}

.loadingpage .logo {
  padding-top: 6rem;
  padding-bottom: 2rem;
  width: 100%;
  text-align: left;
}

.loadingpage .logo img {
  height: 3.5rem;
  margin: 0 auto;
}

.loadingpage .container {
  box-sizing: border-box;
  height: 100%;
  width: 50rem;
  padding: 2.5rem 8rem 2.5rem 8rem;
}

.loadingpage .spinner-container {
  display: flex;
  align-content: 'center';
  gap: 10px;
  color: var(--light-color);
}

.spinner-container {
  display: flex;
  align-content: 'center';
  gap: 10px;
}

/* Mobile Devices */
@media (max-width: 1023px) {
  body .desktop-layout {
    display: none;
  }
}
@media (min-width: 1024px) {
  body .not-mobile-optimized {
    display: none;
  }
}

.loadingtext::after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: ' ';
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
